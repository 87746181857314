/**
 * Reset some basic elements
 */
@use "sass:color";
@use "sass:list";

body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font: $base-font-weight list.slash($base-font-size, $base-line-height) $base-font-family;
    color: $text-color;
    background-color: $background-color;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: 0.5 * $spacing-unit;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
    margin: 0 auto;
    color: red;
    text-align: center;
    font-family: monospace;
}

figcaption {
    font-size: $small-font-size;
}

.insidecaption {
    margin-top: calc(-3em);
    margin-right: 1ex;
    float: right;
}


/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.25em;
    margin-top: 0.75em;
    font-weight: bold;
}

h1 {
    font-size: 1.8em;
    margin-top: 2em;
    margin-bottom: 0.5em; 
    font-weight: $demi-font-weight;
}

h2 {
    font-size: 1.6em;
    margin-top: 1.5em;
    margin-bottom: 0.5em; 
    font-weight: $demi-font-weight;
}

h3 {
    font-size: 1.2em;
    margin-top: 1.0em;
    margin-bottom: 0.5em;
    font-weight: $demi-font-weight;
}

h4 {
    font-size: 1.0em;
    font-weight: $demi-font-weight;
}

// Do not create a blank space at the top of the page
.post-title {
    margin-top: 0.75em;
}

.header-anchor {
    font-size: 16px;
    opacity: 0.0;
}

h1:hover, h2:hover, h3:hover, h4:hover, h5:hover, h6:hover {
    .header-anchor {
        opacity: 1.0;
    }
}

/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: color.adjust($brand-color, $lightness: 15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: 0.5 * $spacing-unit;
    font-size: $base-font-size;
    font-style: oblique;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-family: "Source Code Pro";
    border: 1px solid $grey-color-light;
    border-radius: 2px;
}

code {
    padding: 0px 4px;
}

pre {
    font-size: $small-font-size;
    padding: 0 8px;
    overflow-x: auto;

    > code {
        border: 0;
        padding: 0;
    }
}

.rouge-table {
    // Compensate for the extra 8px of padding added by the extra <pre>
    margin-left: -8px;

    pre {
        border: none;
        margin: 0 auto;
    }
    .gutter {
        color: $grey-color;
        background-color: #f8f8f8;
        text-align: right;
        padding: 0 4px;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: 0.5 * $spacing-unit;
        padding-left: 0.5 * $spacing-unit;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 32px;
        height: 32px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}



/**
 * More subtle HR
 */
hr {
    color: color.adjust($grey-color, $lightness: 25%);
    border-style: dashed;
}



/**
 * Add some extra space in the post listing
 */
.post-list article {
    margin: 2em auto;
}
.readmore {
    font-style: italic;
}


/**
 * Target highlighting
 */
:target {
    background-color: $target-color;
}


/**
 * Tables
 */
table {
    margin: 0 auto;
    border-collapse: collapse;

    td {
        border: solid 1px gray;
        padding: 2px 1ex;
    }
}


/**
 * Definition lists
 */
dl {
    dt {
        margin-top: 1em;
        font-weight: 700;
        font-style: oblique;
    }

    dd {
        margin-left: 2em;

        p {
            margin: 0 auto;
        }
    }
}
