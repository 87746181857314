/**
 * Green hero bar (on the front page)
 */

.hero {
  margin: 0;
  padding: 1ex 0;
  text-align: center;
  box-shadow: 0px 0px 4px 1px $shadow-color inset;
  /* Thanks to subtlepatterns.com for this beautiful pattern:
   * (ref: http://subtlepatterns.com/paper-fibers/)
   */
  background-image: url("/img/paper_fibers.png"), radial-gradient(circle, #a6f05c, #4f8f22);

  h1, h2 {
    line-height: 1.6;
    font-weight: normal;
  }

  h1 {
    margin: 0;
    font-size: 26px;
    color: #4a463e;
  }

  h2 {
    font-size: 18px;
    color: #4a463e;
  }

  img {
    border-radius: 50%;
    max-height: 250px;
    margin: 1em;
    box-shadow: 0px 0px 4px 1px $shadow-color;
  }

}
