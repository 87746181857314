@charset "utf-8";

@use "sass:color";

// Our variables
$base-font-family: "system-ui", "Open Sans", sans-serif;
$base-font-size:   19px;
$base-font-weight: 400;
$demi-font-weight: 600;
$small-font-size:  $base-font-size * 0.875;
$smaller-font-size:$small-font-size * 0.875;
$base-line-height: 1.6;

$spacing-unit:     30px;

$text-color:       #303030;
$background-color: #ffffff;
$brand-color:      #2a7ae2;
$target-color:     #fdffd3;

$grey-color:       #828282;
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);

$purple-color-dark:  #4f4477;
$purple-color:       color.adjust($purple-color-dark, $lightness: 20%);
$purple-color-light: color.adjust($purple-color, $lightness: 30%);

$shadow-color:     rgba(64, 64, 64, 0.5);

// Width of the content area
$content-width:    950px;

$on-palm:          650px;
$on-laptop:        900px;

$post-width:       $content-width;


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "fonts",
        "base",
        "layout",
        "hero",
        "syntax-highlighting"
;
